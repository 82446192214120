@import './normalize.css';

html{
    scroll-behavior: smooth;
    width:100%;
}

:root{
    --color-primary:#fff;
    --color-secondary:#262622;
    --color-accent:#385941;
    --color-brown:#Bd6a3a;
    --color-light:#f6f5f1;
    --color-grey:#F7F8F7;
} 

*,
*::after,
*::before {
    margin:0;
    padding:0;
    box-sizing: border-box;
}

a{
    text-decoration: none;
    color:var(--color-secondary);
}


.container{
    max-width:1920px;
    margin:0 auto;
}


.container--small{
    max-width:1440px;
    margin:0 auto;
}

button {
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
}


body{
    font-family: 'Poppins', sans-serif;
    color:var(--color-secondary);
    line-height:2;
    font-weight: 400;
    letter-spacing: 0.005em;
}


h1{
    font-family: 'Lora', serif;
    font-weight: 400;
    font-style: normal;
    color:var(--color-secondary);
    
}

h2{
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin-bottom: 1rem;
}

h3{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin-bottom: 1rem;
}

h4, 
h5{
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}


h2,h3,h4{
    color:var(--color-secondary);
}

h1{
    font-size: 4.5rem;
    line-height: 1.1;
    letter-spacing:-0.025em;
}

h2{
    font-size:3.5rem;
    letter-spacing: -0.001rem;
    font-weight: 300;
}
h3{
    font-size:2.5rem;
}

h4{
    font-size:1.5rem;
}

h5{
    font-size:1.25rem;
}

h6{
    font-size:1.25rem;
}

p,li{
    font-size: 1.25rem;
    letter-spacing: 0.02em;
}

 ul{
    padding-left: 4rem;
}


.bold{
    font-weight: 500;
}

.strong{
    font-weight: 600;
}

/* Tablet (768px - 1024px) */
@media (max-width: 1600px) {
    h1 {
        font-size: 3.5rem;
    }
    h2 {
        font-size: 3rem;
        line-height:1.1;
    }
    h3 {
        font-size: 2rem;
    }
    h4 {
        font-size: 1.3rem;
    }
    h5 {
        font-size: 1.1rem;
    }

    h6{
        font-size:1.1rem;
    }

    ul{
        padding-left: 2rem;
    }
}

/* Mobile (480px - 767px) */
@media (max-width: 768px) {
    h1 {
        font-size: 2.8rem;
    }
    h2 {
        font-size: 2.2rem;
    }
    h3 {
        font-size: 1.8rem;
    }
    h4 {
        font-size: 1.2rem;
    }
    h5 {
        font-size: 1rem;
    }
    h6 {
        font-size: 1rem;
    }
    p {
        font-size: 1rem;
        line-height:1.8;
    }

    ul{
        padding-left: 1rem;
    }

    li{
        font-size: 1rem;
        line-height: 1.5;
    }

}

/* Small Mobile (Below 480px) */
@media (max-width: 480px) {
    h1 {
        font-size: 2.2rem;
    }
    h2 {
        font-size: 1.8rem;
    }
    h3 {
        font-size: 1.5rem;
    }
    h4 {
        font-size: 1.1rem;
    }
    h5 {
        font-size: 0.95rem;
    }
    
  
}

/* flex */
.flex{
    display: flex;
}

/* back-to-top */

#back-to-top{
    cursor: pointer;
    color:#26262283;
    position: fixed;
    bottom:2rem;
    right:2rem;
    font-size:2rem;
    border: 3px solid #26262283;
    padding:0 1.2rem;
    background-color: var(--color-primary);
    outline:0;
    z-index: 1000;
    transition: 0.3s;
    border-radius: 50px;
}

#back-to-top:hover{
    background-color:var(--color-accent);
    color: var(--color-primary);
    border: 2px solid  var(--color-primary);
}

@media (max-width:768px) {
#back-to-top{    
    font-size:1.5rem;
    padding:0 0.8rem;
    border: 3px solid #26262283;
}
}

/* block */

.block{
    --padding-vertical:6rem;
    padding:var(--padding-vertical) 2rem;
}

.block--inner{
     --padding-vertical:2rem;
    padding:var(--padding-vertical) 4rem;
}

.block--vertical{
     --padding-vertical:2rem;
    padding:var(--padding-vertical) 0;
}

@media (max-width: 768px){
    .block{
        --padding-vertical:4rem;
        padding:var(--padding-vertical) 1.5rem;
    }

    .block--inner{
        --padding-vertical:2rem;
        padding:var(--padding-vertical) 0;
    }

}


/* margin */

/* .margin--block{
    margin:0 2rem;
  }

.margin-bottom--small{
    margin-bottom:1rem;
}


.padding-top--block{
    padding-top: 3rem;
} */

/* text-align */
.text-align--center{
  text-align: center;
}

.text-align--left{
  text-align:left;
}  

/* hero */

.hero-star img{
    width:260px;
    height:auto;
    padding-right:1rem;
}

.hero{
    height:auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.hero-left{
    max-width:40%;
    margin:0 auto;
    overflow:hidden;
    position: relative;
}

.hero-left picture{
    display: block;
    width:100%;
    height:100%;
}

.hero-left img{
    width:100%;
    height:100%;
    display: block;
    object-fit: cover;
}

.hero-right{
    width:60%;
    justify-content: center;
}

.hero-right--inner {
    max-width: 70%;
}

.hero-btn{
    flex-direction: row;
    justify-content: flex-start;
}


.hero-content{
    max-width: 800px;
}

@media (max-width: 1366px){
    .hero{
        flex-direction: column;
        height:auto;
    }


    .hero-left{
        max-width:100%;
        height:30vh;
    }

    .hero-left picture{
        width:100%;
        height:auto;
    }

    .hero-left img{
        width:100%;
        height:auto;
    }

    .hero-right{
    width:100%;
}

.hero-btn{
    align-items: center;
}
}

@media (max-width: 768px){
        .hero-star picture{
            width:40vw;
            padding-bottom: 0.5rem;
        }

         .hero-star img{
            width:40vw;
            padding-bottom: 0.5rem;
        }

       .hero-star p {
        line-height: 1.2;
    }

    .hero-right--inner {
    max-width: 100%;
}
}

/*large phone */
@media (max-width: 600px){
    .hero-star{
        flex-direction: column;
    }
   
}



/* button */

.btn{
    padding: 1.2rem 1.875rem;
    outline:none;
    border:2px solid var(--color-accent);
    cursor:pointer;
    white-space:nowrap;
    text-align: center;
    font-size: 1.25rem;
    margin-top: 2rem;
    border-radius: 50px;
    margin-right:1rem;
    font-weight: 400;
}


.btn--primary{
    background-color: var(--color-accent);
    color:var(--color-light);
}

.btn--secondary{
background-color:#f2eeeb7a ;
}


.btn--small{
    padding: 0.8rem 1rem;
}

.btn--nav{
    margin:0;
}

.btn--large{
    width:20rem;
}

.btn:hover{
    background-color: var(--color-brown);
    color:var(--color-secondary);
    border:2px solid var(--color-brown);
}



@media (max-width: 1024px){

    .hero-btn{
        flex-direction: column;
    }

    .btn{
     padding:0.6rem 1.875rem;
    }
   
    .btn--small{
     padding: 0.5rem 2rem;

    }

    .btn--nav-wrap{
   margin:0.6rem auto;
    }
   
    }


/* Carousel */
.carousel {
  overflow: hidden;
  width: 100%;
}

.carousel-track {
  display: flex;
  width: max-content;
  align-items: center;
  gap:5rem;
}


/* icon */

.icon{
    width:30px;
    height:30px;
}
/* 
.icon--secondary{
    fill:var(--color-secondary);
}

.icon--white{
    fill:#fff;
}

.icon--pin{
    margin:0 0.4rem 0 0.4rem;
}

.icon--fee{
 font-size:3rem;
padding-bottom:0.5rem;
}

.icon--fas{
    background-color: var(--color-accent);
} */

/* callopsible */
.collapsible__header{
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom:0.5px solid #fff;
    margin-bottom: 1rem;
}

.collapsible__heading{
    margin-top:0;
}

.collapsible__chevron{
    transform: rotate(-90deg);
    transition:transform 0.3s;
}

.collapsible__content{
    max-height:0;
    overflow: hidden;
    opacity:0;
    transition:all 0.3s;
}

.collapsible--expanded .collapsible__chevron{
    transform: rotate(0);
}

.collapsible--expanded .collapsible__content{
    max-height:100vh;
    opacity:1;
}

/* list */

.list{
    list-style: none;
    padding-left:0;
}

@media screen and (min-width:768px){
    .list__tablet{
        list-style: outside;
    }
}

.list__content{
    margin-top:0;
    padding-left:0;
}

/* nav */

.nav__header{
    border-bottom: 1.5px solid var(--color-grey);
}

.nav__logo{
    display:flex;
    align-items: center;
    width:100%;
}

 .nav__logo img{
        max-width:250px;
        margin:0.5rem 0;
    }


@media (max-width:768px){
.nav__brand{
    max-width:100px;
}
 
}

@media (max-width:480px){
.nav__brand{
    max-width:50px;
}
    .nav__logo img{
        max-width:180px;
    }
}




.nav{
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    align-items: center;
    font-weight: 400; 
    text-transform: uppercase;
    align-items:center;
}

.nav__list{
    width:100%;
    margin:0;
}

.nav__item{
    display: flex;
    justify-content: center;
    padding:1rem 2rem;
    font-size: 1rem;
}

.nav__item:hover{
   transition:color 0.3s;
   background-color: var(--color-accent);
   color:var(--color-primary); 
   font-weight: 300;
}

.nav__toggler{
  opacity:1;
  cursor:pointer;
  position:absolute;
  top:2px;
  right:0;
  margin:0.6rem 0.8rem 0 ;
  fill:var(--color-accent); 
  transition:all 0.15s;
}

.nav__toggler__close{
   opacity:0;
   width: 20px;
   margin:0 2rem 0 ;
   fill:#fff;
}

.nav.collapsible--expanded .nav__toggler{
    opacity:0; 
    fill:#fff;
}
.nav.collapsible--expanded .nav__toggler__close{
    opacity:1; 
    fill:var(--color-accent);
    
}

@media screen and (min-width:1024px){

    .nav__item{
        font-size: 1.25rem;
    }


    .nav__toggler, .nav__toggler__close{
        display:none;
    }

    .nav__list{
        max-height:100%;
        opacity: 1;
        display:flex;
        width:auto;
    }
    

    .nav__item:hover>a{
        color:var(--color-primary);
    }

    .nav__item:hover{
        background-color: var(--color-accent);
        transition:background-color 0.3s;
    }

    .nav-bottom-border{
        border-bottom: 0.5px solid var(--color-primary);
     }   

}


@media (max-width:768px){

   .nav__item{
     border-bottom: 1px solid var(--color-grey);
   }
}

 
.text__link{
    
    color:var(--color-accent);
}

.text__link:hover{
    color:#525353;
}


/* profile */

.profile--block{
    background-color: var(--color-light); 
}

.profile-left{
    max-width:40%;
    margin:0 auto;
    overflow:hidden;
    position: relative;
}

.profile-left picture{
    display: block;
    width:100%;
    height:100%;
    max-height: 500px;
}

.profile-left img{
    width:100%;
    height:100%;
    display: block;
    object-fit: cover;
}

.profile-right{
    width:100%;
    height:auto;
}


@media (max-width: 1366px){
    .profile{
        flex-direction: column;
        height:auto;
    }

    .profile--block h2{
        text-align: center;
    }

    .prfile-right .read-more {
        text-align: left;
    }


    .profile-left{
        max-width:100%;
        height:40vh;
        
    }

  
    .profile-left picture{
        width:100%;
        height:50vh;
    }

    .profile-left img{
           
        width:100%;
        height:50vh;
    }

}



/* service */

 .service--block{
     background:var(--color-grey);
 }


 @media (max-width: 1366px){


    .service--block h2,h3,p{
        text-align: center;
    }
 }



.content {
    display: none;
}
.read-more {
    color: var(--color-accent);
    cursor: pointer;
    display: inline-block;
}



/* fee */

/* .fee__background{
    background:var(--color-accent);
}

.fee__body{
    margin:5rem auto;
    
}

table:hover{
    background-color: var(--color-secondary);
    transition: background-color 0.2s ease-in;
}

table,
td,
th{
    border:1px solid var(--color-primary);
    border-collapse: collapse;
    padding:2rem 6rem;
    color:#fff;
    font-weight: 300;
}

thead, tbody{
    text-transform: uppercase;
}

.fee{
    font-weight: 400;
    letter-spacing: 0.1rem;
    font-size:3rem;
}

.fee__small{
    font-size:1.2rem;
    letter-spacing: 0.2rem;   
}
.text-transform-none{
text-transform: none;
}

.fee__learn-more{
    background-color: var(--color-primary);
    color:var(--color-secondary);
    margin-bottom:4rem;
    text-transform: uppercase;
}

.fee__learn-more:hover{
    background-color:rgba(255, 255, 255, 0.525);
}

.leran-more-wrap{
    padding:0rem 9rem; 
} */


/* insurance */
/* .insurance__content{
    padding:1.5rem 2rem;
}

@media screen and (min-width:768px) { 
    .left__heading-wrap{
        justify-content:center;
    }

    .fee{
        font-size:2.2rem;
    }

    .fee__small{
        font-size:1.8rem;
    }
}

@media screen and (min-width:1024px){
    .left__heading-wrap{
        justify-content:flex-start;
    }
    .fee{
        font-size:3.2rem;
    }
    .insurance__heading-wrap{
        justify-content:flex-end;
   
    }
    .insurance__content{
        text-align: start;
    }
} */

/* location */
/* .location__background{
    background: var(--color-primary);
}

.location__body{
   margin:5rem auto;
}

.location__map{
    margin:1rem auto;
    padding:2rem;   
     width:100%;
}

.location__body a:hover,
.location__body a:focus{
    color:var(--color-accent);
}

.location__office-description{
    color:#666;
}

@media screen and (max-width:300px){
    .location__gmail{
        font-size: 1.2rem;
    }
}

@media screen and (min-width:1024px) {
   
    .location__body{
        text-align: left;
        margin-left:4rem;
    }
} */

/* office */
/* .office__image{
    width:100%;
    margin:0 auto;
    padding:2rem; 
} */

/* social-prove */
.social-prove{
    flex-direction: row; 
    justify-content: space-around;
    gap:3rem;
}


.social-prove__each{
    width:33%;
    flex-direction: column;
    align-items: center;
    gap:0.5rem; 
}

.social-prove__each p{
text-align: left; 
}

.social-prove__each img{
    max-width:200px;
    max-height:200px; 
}

.social-prove p{
    line-height: 1.5;
}

 @media (max-width: 1366px){
.social-prove{
    flex-wrap: wrap;
}

 }

@media (max-width: 768px) {
.social-prove{
    flex-direction: column;
    align-items: center;
}

.social-prove__each{
    width:70%;
}

}

/* form */

textarea{
   width:100%;
   height:400px;
   resize:none;
   line-height: 1.5;
}

.form-btn-wraper{
    justify-content: flex-end;
}

.form-btn{
    outline: 0;
    border:0;
    background-color:  var(--color-accent);
    white-space: nowrap;
    color:#fff;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 1px;
   
}

.form-btn:hover{
    background-color: var(--color-brown);
    color:var(--color-secondary);
}

.form-control{
    outline:0;
    border:2px solid #D9DDDB;
    background-color: #F7F8F7;
    letter-spacing: 1px;
    color:#525353;
    transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
    padding: 1.4rem 1rem;
    border-radius: 10px;
    font-size: 1.2rem;
}

.form-control:focus{
    border:2px solid  var(--color-accent);;
   
}

.form-control-message{
    border:2px solid #D9DDDB;
    transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
}

.form-control-message:focus{
    border:2px solid  var(--color-accent);;
    
}

.form-group{
    width:100%;
    gap:3rem;
    justify-content: space-around;
}


.form-column{
    width:100%;
    flex-direction: column;
    margin-bottom:1rem;
}

.form-label{
    font-size:1.25rem;
}


@media (max-width: 768px) {

.contact-us p{
    text-align:left;
}


.form-group{
    flex-direction: column;
    gap:0;
}

.form-btn-wraper{
    justify-content: center;
}

.form-label{
    font-size:1.1rem;
}
.form-control{
    font-size: 1rem;
}

}


/* footer */

.footer{
    background-color: var(--color-accent);
    color:var(--color-primary);
}

.footer p{
       text-align: left;
}

.footer a{
    color:var(--color-primary);
}

.footer-top{
    justify-content: space-between;
    flex-wrap:wrap;
}

.footer__part{
    margin: 0 1rem 2rem;
}


iframe{
    width:100%;
    height:500px;
}

.footer__bottom{
    justify-content:space-between;
    flex-wrap: wrap;
    gap:1.5rem;
}

.footer__image img{
    max-width:250px;
    height:auto;
    border-radius: 10px;
}

.footer__social{
    justify-content: center;
    align-items: center;
    gap:2rem;
}

.fab{
 font-size:2.5rem;
}

.fab:hover{
  color:var(--color-brown);

}
@media (max-width:768px){
.footer__bottom{
    flex-direction: column;
    align-items: center;
}
}

